export default {
  //Change The Website Template
  name: 'Woojin Oh',
  headerTagline: [
    //line 1 for Header
    'Hello 👋\xa0\xa0I am Woojin Oh',
    //line 2 for Header
    'Web Developer',
  ],
  // Change Projects Here
  projects: [
    {
      id: 1,
      title: 'Burger Builder App',
      skills: 'React, CSS',
      shortDesc: 'Building a Hamburger Builder App with React Library.',
      imageFull: [
        '/images/portfolio/01-full.jpg',
        '/images/portfolio/01-full-2.jpg',
      ],
      imageThumbnail: '/images/portfolio/01-thumbnail.jpg',
      fullDesc: [
        'This project is to build the Hamburg Builder app using the React Library. It includes functions such as custom burger manufacturing and ordering, and order details management. Backend and database were built using Firebase services.',
        'Class Components, Funtional Components, Higher-Order Components, Redux, Forms, Http Access, Authentication, Routing, React Hooks were used for this app.',
      ],
      date: 'May 2020',
      client: 'Woojin Oh',
      url: 'https://hamburger-builder-d2150.firebaseapp.com/',
      github: 'https://git.io/JvPJY',
    },
    {
      id: 2,
      title: 'Employee Portal App',
      skills: 'Angular, MongoDB, Node.js',
      shortDesc: 'Building a Employee Portal App with Angular Framework.',
      imageFull: [
        '/images/portfolio/02-full.jpg',
        '/images/portfolio/02-full-2.jpg',
      ],
      imageThumbnail: '/images/portfolio/02-thumbnail.jpg',
      fullDesc: [
        "It's an employee portal where users can sign in through a web browser. Once inside they have access to all of their professional information such as pay history, hours worked, and staff schedules. As well as a system for allowing staff to contact each other in real-time through instant messaging with an individual person or multiple teams. We'll make a control center with access only given to upper management where they can enter a staff's hours worked, upload their pay statements, manage schedules, and distribute any other information to staff that would be given from upper management.",
        'Components, Databinding, Directives, Services, Dependency Injection, Forms, Http Access, Authentication, Routing, Observables were used for this app.',
      ],
      date: 'May 2020',
      client: 'Woojin Oh',
      url: '',
      github: '',
    },
    {
      id: 3,
      title: 'Recipe Book App',
      skills: 'Angular',
      shortDesc: 'Building a Web App with Angular Framework.',
      imageFull: [
        '/images/portfolio/03-full.jpg',
        '/images/portfolio/03-full-2.jpg',
      ],
      imageThumbnail: '/images/portfolio/03-thumbnail.jpg',
      fullDesc: [
        'This is my first Angular project.',
        'This project was to create a web application in Angular that will serve as a recipe book site.',
        'Components, Databinding, Directives, Services, Dependency Injection, Forms, Http Access, Authentication, Routing, Observables were used for this app.',
        'Test Id : test@test.com | Test Password : 123456',
      ],
      date: 'March 2020',
      client: 'Woojin Oh',
      url: 'https://ng-course-recipe-book-c3c07.firebaseapp.com/',
      github: 'https://git.io/Jv2cO',
    },
    {
      id: 4,
      title: 'Portfolio Website',
      skills: 'React',
      shortDesc: 'Building my portfolio website with React Library.',
      imageFull: ['/images/portfolio/04-full.jpg'],
      imageThumbnail: '/images/portfolio/04-thumbnail.jpg',
      fullDesc: [
        'This is my portfolio website.',
        'This website is created using React and hosted in firebase.',
      ],
      date: 'January 2020',
      client: 'Woojin Oh',
      url: 'http://www.halifaxweb.dev',
      github: 'https://git.io/JvqYr',
    },
    {
      id: 5,
      title: 'Photo Feed App',
      skills: 'Laravel, MySQL, Apache',
      shortDesc: 'Building a Full Stack Web App with Laravel Framework.',
      imageFull: ['/images/portfolio/05-full.jpg'],
      imageThumbnail: '/images/portfolio/05-thumbnail.jpg',
      fullDesc: [
        'This is my first Laravel project.',
        'This project was to create a web application in PHP that will serve as a custom Social Media Feed site. The site consists of two areas:',
        '1) An administrative back-end area, the basic structure (i.e. users, posts, themes) of a website can be dynamically created and modified by only authorized users with the appropriate permissions. The additions and changes to the site’s administrative structure will all be stored in the custom SQLite/MySQL databases that you will create to support your Social Media Feed site.',
        '2) A front-end site area which will dynamically display posts based on the structure stored in the database. The front-end site will be viewable by all users including guests, but users can register and on subsequent visits log in in order to be able to contribute and make some changes to feed content.',
        'The site was built using the LARAVEL Application Framework and styled using BOOTSTRAP.',
      ],
      date: 'December 2019',
      client: 'NSCC INET2005',
      url: '',
      github: 'https://git.io/JvtiO',
    },
    {
      id: 6,
      title: 'Arirang',
      skills: 'WordPress, HTML, PHP, MySQL',
      shortDesc: 'Building a Website for local business with WordPress.',
      imageFull: ['/images/portfolio/06-full.jpg'],
      imageThumbnail: '/images/portfolio/06-thumbnail.jpg',
      fullDesc: [
        'The project was to renew the Arirang restaurant website. The most important goal was to create a restaurant website where customers can order using the website. Therefore, the website included functions such as shopping cart and order system, delivery method selection, payment method selection, order management, and order statistics and so on.',
        'WordPress, PHP, HTML, CSS, and JavaScript technologies were used to build the website, and MySQL was used for the database.',
      ],
      date: 'Apr 2020',
      client: 'Arirang Restaurant',
      url: 'http://www.ariranghalifax.ca/',
      github: '',
    },
    {
      id: 7,
      title: 'JS Full Stack App',
      skills: 'React, MongoDB, Node.js',
      shortDesc: 'Building a Full Stack Web App with JavaScript.',
      imageFull: ['/images/portfolio/07-full.jpg'],
      imageThumbnail: '/images/portfolio/07-thumbnail.jpg',
      fullDesc: [
        'This is my first Full Stack JavaScript project.',
        'This web app allows users to create / read / update / delete contact information. It also provides user registration, login and logout functions and proceeds with the authentication process using JWT.',
        'React was used to build the front-end, and Node.js and Express.js were used for the back-end. MongoDB and Mongoose were used for the database.',
      ],
      date: 'December 2019',
      client: 'NSCC PROG3017',
      url: 'http://w0419410.herokuapp.com/',
      github: 'https://git.io/JvtiY',
    },
    {
      id: 8,
      title: 'ILBUNJI BISTRO',
      skills: 'Laravel, MySQL, Apache',
      shortDesc:
        'Building a Website for local business with Laravel Framework.',
      imageFull: [
        '/images/portfolio/08-full.jpg',
        '/images/portfolio/08-full-2.jpg',
      ],
      imageThumbnail: '/images/portfolio/08-thumbnail.jpg',
      fullDesc: [
        'ILBUNJI BISTRO is a restaurant that started its new business in December 2019.',
        'They wanted to create a website to promote their new business and make their customers more comfortable.',
        'And they requested me to design menus and brochures.',
        'Laravel, PHP, HTML, CSS and JavaScript technologies were used to build the website, and Apache and MySQL were used for the server and database.',
        'And I used Photoshop to design menu and brochure.',
      ],
      date: 'November 2019',
      client: 'ILBUNJI BISTRO',
      url: 'http://ilbunjibistro.com',
      github: '',
    },
    {
      id: 9,
      title: 'NSCC IT Jobfair',
      skills: 'Laravel, MySQL, Apache',
      shortDesc: 'Building a NSCC IT Jobfair Website with Laravel Framework.',
      imageFull: ['/images/portfolio/09-full.jpg'],
      imageThumbnail: '/images/portfolio/09-thumbnail.jpg',
      fullDesc: [
        'Nova Scotia Community College (NSCC) in Halifax has conducted an annual IT Job Fair since 2015 and desires to make its organization more efficient. To accomplish this, NSCC is interested in creating a Job Fair website to promote and organize the 2020 IT Job Fair.',
        'This project was to create a website that allows students and IT organizations to register for the 2020 IT Job Fair. The website will allow NSCC administration to organize event details and the event schedule. The website will effectively deliver relevant event information to all users.',
        'I participated in this project as a full stack developer.',
        'Laravel, PHP, HTML, CSS and JavaScript technologies were used to build the website, and Apache and MySQL were used for the server and database.',
      ],
      date: 'January 2020',
      client: 'NSCC IT Campus',
      url: 'https://itjobfair.ca/',
      github: '',
    },
    {
      id: 10,
      title: 'CSS Playground',
      skills: 'CSS, HTML, JavaScript',
      shortDesc: 'This is my playground to learn and play with CSS.',
      imageFull: ['/images/portfolio/10-full.jpg'],
      imageThumbnail: '/images/portfolio/10-thumbnail.jpg',
      fullDesc: [
        "As a web developer, I've found learning CSS to be more difficult then it should be. So I started to build CSS Playground to learn and practice interactive CSS skills.",
        'I learned various skills of CSS here including flex, grid, responsive design, animation, transitions, etc.',
      ],
      date: 'May 2020',
      client: 'Woojin Oh',
      url: 'https://codepen.io/collection/nbaYpr',
      github: 'https://github.com/WoojinFive/CSS_Playground',
    },
    {
      id: 11,
      title: 'Atlantic Summit',
      skills: 'WordPress, PHP, JavaScript, MySQL',
      shortDesc: 'Development of the company’s website using WordPress, JavaScript and PHP.',
      imageFull: ['/images/portfolio/11-full.jpg'],
      imageThumbnail: '/images/portfolio/11-thumbnail.jpg',
      fullDesc: [
        'Development of the company’s website using WordPress, JavaScript and PHP.', 'Development of Customer Relationship Management system and database design for it.', 'articipation in all processes from building server to service deployment.',
      ],
      date: 'November 2020',
      url: 'https://www.asiccareer.com',
    },
    {
      id: 12,
      title: 'SaltWire.com',
      skills: 'Python, Django, JavaScript, PostgreSQL',
      shortDesc: 'Build, test, publish, and maintain the company\'s core website and all integrations.',
      imageFull: ['/images/portfolio/12-full.jpg'],
      imageThumbnail: '/images/portfolio/12-thumbnail.jpg',
      fullDesc: [
        "SaltWire.com is the largest news media company in Atlantic Canada with more than 474 employees and more than $105 million in sales. Its website ranks among the top 1500 sites in the entire country.",
        'I built, tested, published, and maintained the company\'s core website and all integrations.',
        '- Python/Django: From concept to deployment, I utilized my Django expertise in all phases of software development including development, maintenance, and support. I also ensured that the company website had 99.5% uptime and all features were operational, including any third-party integration, APIs, Media Hosting, Payments, Marketing and Mobile apps.',
        '- Linux/Ubuntu: Assured that server resources including databases are effectively distributed to boost website speed and to provide a better customer experience. During the website attack, I made sure data was safe, server resources were protected, and the website was available to stay online. Created modern development practices that helped the team deploy code in production more effectively and in a safe manner.',
        '- PostgreSQL: I have optimized the performance of the database using techniques such as database query optimization and indexing.',
      ],
      date: 'Nov 2020 ~',
      url: 'https://www.saltwire.com',
    },
    {
      id: 13,
      title: 'Chicking',
      skills: 'WordPress, Elementor',
      shortDesc: 'Building a Website for local business with WordPress and Elementor.',
      imageFull: [
        '/images/portfolio/13-full.png',
        '/images/portfolio/13-full-2.png'
      ],
      imageThumbnail: '/images/portfolio/13-thumbnail.png',
      fullDesc: [
        'Development of the local business website using WordPress and Elementor.',
      ],
      date: 'April 2022',
      url: 'https://chicking4u.ca/',
    },
  ],
  timelines: [
    {
      id: 1,
      date: 'September 2011',
      title: 'University Of Seoul, Seoul, Korea',
      desc: ['School of Electrical and Computer Engineering'],
      image: '/images/about/1.jpg',
    },
    {
      id: 2,
      date: '2011-2015',
      title: 'Samsung Display',
      desc: ['Flexible OLED Research & Development Center', '- Engineer'],
      image: '/images/about/2.jpg',
    },
    {
      id: 3,
      date: '2016-2017',
      title: 'Sell Your Travel',
      desc: ['Planning & Management Team', '- Website Manager & Team Manager'],
      image: '/images/about/3.jpg',
    },
    {
      id: 4,
      date: '2018-2020',
      title: 'Nova Scotia Community College',
      desc: ['IT Web Development'],
      image: '/images/about/4.jpg',
    },
    {
      id: 5,
      date: '2020~',
      title: 'SaltWire Network',
      desc: ['DevOps Team', 'Full Stack Developer'],
      image: '/images/about/5.jpg',
    },
  ],
};
